import { React, useEffect, useCallback, useState } from 'react';
import './home.css'

const Home = () => {
    const svgs = [
        <svg class='home_title-size' xmlns="http://www.w3.org/2000/svg" data-name="Layer 2" viewBox="0 0 61.2 70.8"><path d="M0 70.8v-1l5-1V2L0 1V0h34.6v1l-5.1 1v67.3h6.2c1.93 0 3.7-.55 5.3-1.65s3.17-2.87 4.7-5.3c1.53-2.43 3.13-5.62 4.8-9.55l5.2-12.4h2l3.5 30.4H0Z" fill="#f1f1f1" data-name="Layer 1" /></svg>,
        <svg class='home_title-size' xmlns="http://www.w3.org/2000/svg" data-name="Layer 2" viewBox="0 0 81 70.8"><path d="M0 70.8v-1l5.1-1L33.6 0h15.2l27.4 68.8 4.8 1v1H42.7v-.5l7-1.4-20.4-51.2H28l-.4 1c-2.27 5.67-3.98 11.08-5.15 16.25-1.17 5.17-1.75 9.72-1.75 13.65s.58 7.42 1.75 10.65c1.17 3.23 2.97 5.8 5.4 7.7s5.58 2.85 9.45 2.85v1H0Zm16.7-28.5v-1l23-2v3h-23Z" fill="#f1f1f1" data-name="Layer 2" /></svg>,
        <svg class='home_title-size' xmlns="http://www.w3.org/2000/svg" data-name="Layer 2" viewBox="0 0 96.8 70.8"><path d="M0 70.8v-1l8-1V2.2L.9 1V0h32l20.9 49.3-1.9-2.3h3.7l-1.2 2.2L65.5 0h31.3v1l-5 1v66.8l5 1v1H61.2v-1l6.1-1V11.6h-1.5L50.1 70.8H36.5L11 14.2H9.5v.5c0 3.07.23 6.75.7 11.05.47 4.3 1.17 8.8 2.1 13.5.93 4.7 2.17 9.18 3.7 13.45 1.53 4.27 3.37 7.93 5.5 11 2.13 3.07 4.6 5.1 7.4 6.1v1H0Z" fill="#f1f1f1" data-name="Layer 3" /></svg>,
        <svg class='home_title-size' xmlns="http://www.w3.org/2000/svg" data-name="Layer 2" viewBox="0 0 76.5 70.8"><path d="M41.7 0c6.47 0 12.32 1.35 17.55 4.05 5.23 2.7 9.42 6.62 12.55 11.75 3.13 5.13 4.7 11.43 4.7 18.9s-1.52 14.08-4.55 19.45c-3.03 5.37-7.17 9.48-12.4 12.35-5.23 2.87-11.18 4.3-17.85 4.3H0v-1l5-1V1.5L0 1V0h41.7Zm-6 1.6c-1.4 0-2.62.03-3.65.1-1.03.07-1.88.17-2.55.3v65.3l2 2h4.2c5.33 0 9.3-3.15 11.9-9.45s3.9-15.22 3.9-26.75c0-6.33-.58-11.85-1.75-16.55-1.17-4.7-2.92-8.37-5.25-11-2.33-2.63-5.27-3.95-8.8-3.95Z" fill="#f1f1f1" data-name="Layer 4" /></svg>,
        <svg class='home_title-size' xmlns="http://www.w3.org/2000/svg" data-name="Layer 2" viewBox="0 0 74 73.8"><path d="M37.2 0c5.07 0 9.82.72 14.25 2.15s8.35 3.63 11.75 6.6c3.4 2.97 6.05 6.8 7.95 11.5C73.05 24.95 74 30.6 74 37.2c0 6.2-.92 11.6-2.75 16.2s-4.42 8.4-7.75 11.4-7.23 5.25-11.7 6.75c-4.47 1.5-9.33 2.25-14.6 2.25s-10.17-.77-14.7-2.3c-4.53-1.53-8.48-3.82-11.85-6.85C7.28 61.62 4.67 57.8 2.8 53.2.93 48.6 0 43.23 0 37.1c0-5.6.9-10.68 2.7-15.25 1.8-4.57 4.35-8.47 7.65-11.7 3.3-3.23 7.23-5.73 11.8-7.5C26.72.88 31.73 0 37.2 0Zm0 1c-2.13 0-3.98.67-5.55 2-1.57 1.33-2.83 3.43-3.8 6.3-.97 2.87-1.68 6.55-2.15 11.05-.47 4.5-.7 9.95-.7 16.35s.23 11.68.7 16.25 1.18 8.28 2.15 11.15c.97 2.87 2.23 5 3.8 6.4 1.57 1.4 3.42 2.1 5.55 2.1s3.95-.65 5.45-1.95c1.5-1.3 2.71-3.38 3.65-6.25.93-2.87 1.62-6.58 2.05-11.15.43-4.57.65-10.12.65-16.65s-.22-11.97-.65-16.5c-.43-4.53-1.12-8.2-2.05-11-.93-2.8-2.15-4.85-3.65-6.15C41.15 1.65 39.33 1 37.2 1Z" fill="#f1f1f1" data-name="Layer 5" /></svg>
    ]

    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [elementPosition, setElementPosition] = useState({ x: 0, y: 0 });
    const [positionDifference, setPositionDifference] = useState({ x: 0, y: 0 });
    const [grabbedIndex, setGrabbedIndex] = useState(null);
    const [ballPosition, setBallPosition] = useState({x:0, y:0});
    const speed = 0.2;

    const handleMouseMove = (event) => {
        setMousePosition({
            x: event.clientX,
            y: event.clientY
        });
    };

    const moveBall = useCallback(() => {
        setBallPosition(currentBallPosition => {
            let distX = mousePosition.x - currentBallPosition.x;
            let distY = mousePosition.y - currentBallPosition.y;
    
            return {
                x: currentBallPosition.x + (distX * speed),
                y: currentBallPosition.y + (distY * speed)
            };
        });
    }, [mousePosition.x, mousePosition.y]);

    const handlePointerDown = (event, index) => {
        const elementRect = event.currentTarget.getBoundingClientRect();
        const elementX = elementRect.left + window.scrollX;
        const elementY = elementRect.top + window.scrollY;

        setGrabbedIndex(index);
        setElementPosition({
            x: elementX,
            y: elementY
        });

        setPositionDifference({
            x: mousePosition.x - elementX,
            y: mousePosition.y - elementY
        });

        setMousePosition({
            x: mousePosition.x,
            y: mousePosition.y
        });
    }

    const handlePointerUp = (event, index) => {
        setGrabbedIndex(null);
    }

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    useEffect(() => {
        const intervalId = setInterval(moveBall, 5);

        return () => clearInterval(intervalId);
    }, [mousePosition, moveBall]);

    return (
        <div onPointerUp={handlePointerUp} style={{cursor: 'none'}}>
            <div className='home_title-centering flex'>
                <div className='flex'>
                {svgs.map((svg, index) => (
                        <div
                            className={`home_letter home_title-spacing ${grabbedIndex === index ? '' : 'home_title-effect'}`}
                            style={grabbedIndex === index ? {position: 'relative', transform: `translateX(${(mousePosition.x - elementPosition.x - positionDifference.x) * 0.6}px) translateY(${(mousePosition.y - elementPosition.y - positionDifference.y) * 0.6}px)`} : {position: 'relative'}}
                            key={index}
                            onPointerDown={(e) => handlePointerDown(e, index)}
                            
                        >
                            {svg}
                        </div>
                ))}
                </div>
            </div>
            <div className='border'>
                    <p>par Deraze Loïc</p>
                </div>
            <div style={{color: "#ffffff"}}>
                <h1>Track Mouse Movement</h1>
                <p>The current mouse position is ({mousePosition.x}, {mousePosition.y})</p>
                <p>The clicked element's position is ({elementPosition.x}, {elementPosition.y})</p>
                <p>Difference between mouse and element position is: Δx = {positionDifference.x}, Δy = {positionDifference.y}</p>
                <p>Grabbed index {grabbedIndex}</p>
                <p>Ball position {ballPosition.x}, {ballPosition.y}</p>
            </div>
            <div class="home_ball" style={{left: `${ballPosition.x}px`, top: `${ballPosition.y}px`}}></div>
        </div>
    );
}

export default Home;